import { Backdrop, Box, Button, Fade, Modal, TextField, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from "@mui/icons-material/Close"
const PostEditModal = ({handleEditModal=()=>{},editFields=null, handleSubmitEdit=()=>{},handleInput=()=>{}}) => {
  return (
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={true}
    onClose={handleEditModal}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{
      backdrop: {
        timeout: 500,
      },
    }}
    className="custom-modal"
  >
    <Fade in={!!editFields}>
      <Box>
        <Box className="modal-body">
          <a onClick={() => handleEditModal(null)} className="close-btn">
            <CloseIcon className="icon" />
          </a>
          <Typography className="main-title" component="h2">
            Edit Post Service
          </Typography>
          <form onSubmit={handleSubmitEdit}>
            <TextField
              type="text"
              label="Service Code"
              fullWidth
              variant="outlined"
              style={{ marginBottom: "20px" }}
              value={editFields?.code}
              onChange={handleInput}
              name="code"
              required
            />
            <TextField
              type="text"
              label="Service Type"
              fullWidth
              variant="outlined"
              style={{ marginBottom: "20px" }}
              value={editFields?.type}
              onChange={handleInput}
              name="type"
              required
            />
            <TextField
              type="number"
              inputProps={{ min: 0, step: 0.01 }}
              label="Service Cost"
              fullWidth
              variant="outlined"
              style={{ marginBottom: "10px" }}
              value={editFields?.cost}
              onChange={handleInput}
              name="cost"
              required
            />
            <Box className="modal-footer">
              <Button
                className="btn btn-outline-primary"
                onClick={handleEditModal}
              >
                Cancel
              </Button>
              <Button className="btn btn-primary" type="submit">
                Add
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Fade>
  </Modal>
  )
}

export default PostEditModal