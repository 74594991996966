import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name : "auth",
    initialState : {user:null},
    reducers : {
        addUser(state , action){
            state.user= action.payload
        },
        clearUser(state){
            state.user=null
        }
    }
})

export default authSlice.reducer;
export const { addUser,clearUser } = authSlice.actions;