import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Links from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./login.scss";
import { loginHandle } from "../../redux/slices/authAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from '../../images/Linton-logo.png';
import Banner from '../../images/login-banner.jpg';

const Login = ({ loginHandle }) => {

  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [remember, setRemember] = React.useState(false)
  const [msg , setMsg] = React.useState('');
 
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault()
    const { data, error } = await loginHandle(email, password , remember);
    if (data) {
      navigate("/");
    }
    setMsg(error?.response?.data?.message);
  }

  return (<>
       <Grid container spacing={2} className="login">
          <Grid className="left" item xs={12} sm={6} md={6}>
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding:"30px",
            minHeight: "calc(100vh - 76px )"
          }}
        >
          <img src={Logo} style={{width:"200px", marginBottom:"20px"}}/>
          <Typography
            style={{ fontFamily: "Nunito, sans-serif", marginTop: "15px" , fontWeight:"700",fontSize:"40px" }}
            component="h1"
            variant="h5"
          >
            Log In
          </Typography>
          <Box component="form" className="login-form" noValidate sx={{ mt: 1 }} onSubmit={handleLogin}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value={remember} onChange={(e) => setRemember(e.target.value)} color="primary" />}
              label="Remember me"
            />
             {msg && <Typography
            style={{ fontFamily: "Nunito, sans-serif", marginTop: "15px", color:"rgb(255,14,14)" , fontWeight:"600",fontSize:"12px" }}
            component="p"
            variant="h5"
          >
            {msg}
          </Typography>}
            {/* <Link to={"/"}> */}
            <Button
              style={{ width: "100%" , padding:"7px" }}
              type="submit"
              fullWidth
              className="btn btn-primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {/* </Link> */}
            <Grid container>
              <Grid item xs>
                <Links href="#" variant="body2">
                  Forgot Password?
                </Links>
              </Grid>
            </Grid>
          </Box>
        </Box>
          </Grid>
          <Grid className="right" item xs={0} sm={6} md={6}>
            <img src={Banner} />
          </Grid>
        </Grid>
    </>
  );
}

const action = {
  loginHandle
}

export default connect(null, action)(Login)

