import React, { useEffect, useState, useRef } from "react";
import API from "../../api/api";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DoneIcon from "@mui/icons-material/Done";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Modal,
  Typography,
  Fade,
  Backdrop,
  Container,
  TextField,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Switch,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import ErrorModal from "../../components/Modals/ErrorModal";
import PostEditModal from "../../components/Modals/PostsModal/PostEditModal";
import PostActivatorModal from "../../components/Modals/PostsModal/PostActivatorModal";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
const formattedDateTime = (date) => {
  const dateTime = new Date(date);
  const formattedDate = dateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  // Format options for date and time
  const timeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  const formattedTime = dateTime.toLocaleTimeString(
    undefined,
    timeFormatOptions
  );

  const formatted = `${formattedDate}`;
  return formatted;
};

const columns = [
  {
    field: "service_code",
    headerName: "Service Code",
    flex: 1,
    renderCell: (params) => {
      return `${params.row.code}`;
    },
  },
  {
    field: "service_type",
    headerName: "Service Type",
    flex: 1,
    renderCell: (params) => {
      return `${params.row.type}`;
    },
  },
  {
    field: "add_on_cost",
    headerName: "Add-On Cost",
    flex: 1,
    renderCell: (params) => {
      return `$${params.row.cost}`;
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return params.row.status.isActive ? "Active" : "Inactive";
    },
  },
  {
    field: "start_date",
    headerName: "Start Date",
    flex: 1,
    renderCell: (params) => {
      return !!params.row?.status?.start_date
        ? formattedDateTime(params.row?.status?.start_date)
        : null;
    },
  },
  {
    field: "end_date",
    headerName: "End Date",
    flex: 1,
    renderCell: (params) => {
      return !!params.row.status?.end_date
        ? formattedDateTime(params.row.status?.end_date)
        : null;
    },
  },
];

const PostServiceGrid = ({ rows, isLoading, setPaginationModel }) => {
  const [startDate, setStartDate] = React.useState(dayjs());
  const [endDate, setEndDate] = React.useState(dayjs());
  // add modal
  const [fields, setFields] = React.useState(null);
  const [editFields, setEditFields] = React.useState(null);
  const boolRef = useRef(false);

  const [serviceError, setServiceError] = useState(null);
  const handleSetServiceError = (data) => {
    setServiceError(data);
  };

  // Handle input change in the edit modal
  const handleInput = (e) => {
    const { name, value } = e?.target;
    setEditFields((p) => ({ ...p, [name]: value }));
  };

  const handleEditModal = (data) => {
    setEditFields(data);
  };


  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    await API.post(`/admin/edit-post-service`, {
      id: editFields._id,
      code: editFields.code,
      type: editFields.type,
      cost: editFields.cost,
    })
      .then((responce) => {
        setEditFields(null);
        setPaginationModel({ bool: boolRef.current });
        boolRef.current = !boolRef.current;
        // Reset the form fields
      })
      .catch((error) => {
        handleSetServiceError(error?.response?.data?.message);
      });
  };

  const handleModal = (data) => {
    setFields(data);
  };

  const handleSubmitActivate = async (e) => {
    e.preventDefault();

    await API.post(`/admin/set-post-service`, {
      id: fields._id,
      start_date: startDate,
      end_date: endDate,
    })
      .then((responce) => {
        setFields(null);
        setPaginationModel({ bool: boolRef.current });
        boolRef.current = !boolRef.current;
        // Reset the form fields
        setStartDate(dayjs());
        setEndDate(dayjs());
      })
      .catch((error) => {
        handleSetServiceError(error?.response?.data?.message);
      });
  };

  const handleDirectActivate = async (id) => {
    await API.post(`/admin/set-post-service-active`, {
      id,
    })
      .then((responce) => {
        setFields(null);
        setPaginationModel({ bool: boolRef.current });
        boolRef.current = !boolRef.current;
        // Reset the form fields
        setStartDate(dayjs());
        setEndDate(dayjs());
      })
      .catch((error) => {
        handleSetServiceError(error?.response?.data?.message);
      });
  };

  const handleDeleteService = async (id) => {
    await API.post(`/admin/delete-post-service`, {
      id,
    })
      .then((responce) => {
        setFields(null);
        setPaginationModel({ bool: boolRef.current });
        boolRef.current = !boolRef.current;
        // Reset the form fields
        setStartDate(dayjs());
        setEndDate(dayjs());
      })
      .catch((error) => {
        handleSetServiceError(error?.response?.data?.message);
      });
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 180,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="action-icon-btn viewBtn">
              <Tooltip title="Activate">
                <IconButton
                  onClick={() => handleDirectActivate(params.row._id)}
                  data={params.row}
                  id="activate-field"
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className="action-icon-btn viewBtn">
              <Tooltip title="Set Timer">
                <IconButton
                  onClick={() => handleModal(params.row)}
                  id="activate-field"
                >
                  <AvTimerIcon />
                </IconButton>
              </Tooltip>
            </div>

            <CustomIcon
             title={"Edit"}
             className="action-icon-btn editBtn"
             cb={()=>handleEditModal(params.row)}
             icon={<EditIcon/>}
            />
                <CustomIcon
             title={"Delete"}
             className="action-icon-btn deleteBtn"
             cb={()=>handleDeleteService(params.row._id)}
             icon={<DeleteForeverIcon/>}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Box className="datatable" sx={{ height: "calc(100vh - 180px)" }}>
      <DataGridPro
        rows={rows}
        getRowId={(row) => row._id}
        columns={[...columns, ...actionColumn]}
        pageSize={5}
        rowsPerPageOptions={[5]}
        loading={isLoading}
        autoHeight
      />
          
      {fields && (
        <PostActivatorModal 
        handleModal={() => handleModal(null)}
        fields={fields}
        handleSubmitActivate={(e)=>handleSubmitActivate(e)}
        endDate={endDate}
        startDate={startDate}
        setStartDate={(e)=>setStartDate(e)}
        setEndDate={(e)=>setEndDate(e)}
      />
      )}
            
      {editFields && (
       <PostEditModal
       handleEditModal={()=>handleEditModal(null)}
       editFields={editFields}
       handleSubmitEdit={()=>handleSubmitEdit()}
       handleInput={(e)=>handleInput(e)}
     />
      )}
        
      {serviceError && (
       <ErrorModal
       reason={serviceError}
       handleErrorModal={()=> handleSetServiceError(null)}
       />
      )}
    </Box>
  );
};

export default PostServiceGrid;
