import React from "react";
import Snackbar from "@mui/material/Snackbar";

export default function AutohideSnackbar({ open, message, onClose, bg="", color="" }) {
  return (
    <Snackbar
    ContentProps={{
      sx: {
        background: bg?bg:"black !important",
        color:color?color:"white !important",
      }
    }}
    anchorOrigin={{ vertical:"top", horizontal:"center" }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      message={message}
    />
  );
}
